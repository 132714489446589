<template>
  <div class="content">
    <div class="core">
      <div class="core-title">智慧城市运行指挥中心</div>
      <div class="core-text">
        整合各委办局的信息化系统,实现部门、区域之间的数据关联和共享,消除“信息孤岛”
        对数据进行融合处理、深度挖掘、决策分析，是智慧城市的核心中枢。
      </div>
      <div class="core-box">
        <div
          class="core-box-item"
          v-for="(item, index) in coreImgs"
          :key="index"
        >
          <img :src="item.url" alt="" />
          <div class="item-text">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {
      coreImgs: [
        {
          url: require("../../../../assets/image/cityImage/icon1.png"),
          name: "公安",
        },
        {
          url: require("../../../../assets/image/cityImage/icon2.png"),
          name: "环保",
        },
        {
          url: require("../../../../assets/image/cityImage/icon3.png"),
          name: "民政",
        },
        {
          url: require("../../../../assets/image/cityImage/icon4.png"),
          name: "交通",
        },
        {
          url: require("../../../../assets/image/cityImage/icon5.png"),
          name: "教育",
        },
        {
          url: require("../../../../assets/image/cityImage/icon6.png"),
          name: "医疗",
        },
        {
          url: require("../../../../assets/image/cityImage/icon7.png"),
          name: "农业",
        },
        {
          url: require("../../../../assets/image/cityImage/icon8.png"),
          name: "旅游",
        },
      ],
    };
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  min-height: calc(100vh - 387px);
}
.core {
  width: 100%;
  // height: 530px;
  height: calc(100vh - 287px);
  // margin-top: 50px;
  padding-left: 15%;
  color: #ffffff;
  background: url("../../../../assets/image/cityImage/组 295.png") no-repeat;
  box-sizing: border-box;
  .core-title {
    font-size: 28px;
    font-weight: 600;
    padding: 60px 0;
  }
  .core-box {
    width: 100%;
    display: flex;
    font-size: 24px;
    text-align: center;
    .core-box-item {
      width: 10%;
      padding: 7vw 0;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
  .core-text {
    height: 80px;
    width: 55%;
    font-size: 28px;
    font-weight: 300;
  }
}
</style>